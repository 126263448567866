<template>
  <div class="UserHome">
    <div class="bottomDiv" style="margin-top: 20px; height: auto">
      <div class="index_title" style="border-bottom: none">分账管理</div>
      <div class="formDiv" style="width: 70vw">
        <div
          v-show="isadmin"
          style="display: flex; justify-content: space-between; width: 70vw"
        >
          <el-form style="margin-bottom: 8px !important">
            <el-select
              v-model="FormData.username"
              placeholder="请选择运营商"
              style="width: 90%; display: flex; justify-content: space-between"
            >
              <el-option
                v-for="aa in users"
                :key="aa.username"
                :value="aa.username"
                :label="aa.username"
              />
            </el-select>
          </el-form>
          <div
            class="BtnSearch"
            style="height: 30px; margin-top: 5px; width: 16vw"
            @click="searchBtn()"
          >
            查询
          </div>
        </div>
        <!-- </div> -->
      </div>
      <!-- 表格部分 -->
      <div class="tableDiv">
        <el-table
          ref="multipleTableRef"
          :data="tableData"
          border
          v-loading="loading"
          style="width: 90%; margin: 20px auto; overflow-y: auto"
        >
          <el-table-column label="账户名称" prop="username" />
          <el-table-column
            label="订单金额(元)"
            prop="pay_money"
            :formatter="rounding"
          />
          <el-table-column
            label="抽成金额(元)"
            prop="by_money"
            :formatter="rounding"
          />
          <el-table-column
            label="微信服务费(元)"
            prop="service_charge"
            :formatter="rounding"
          />
          <el-table-column
            label="到账金额(元)"
            prop="to_the_account"
            :formatter="rounding"
          />
          <el-table-column label="分账时间" prop="create_time" :formatter="formatDate"/>
        </el-table>

        <div class="flex1">
          <p>
            分账总次数：<span>{{ tableData1.count }}</span>
          </p>
          <p>
            分账总收入(元)：<span>{{ tableData1.sum }}</span>
          </p>
        </div>
        <!-- 退费弹窗 -->
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive, toRefs } from "vue-demi";
import { independent_account_system, operator_data } from "@/request/api";
import { ElMessage } from "element-plus";

export default {
  name: "SearchMan",
  setup() {
    const data = reactive({
      //表单数据
      carList: [
        {
          value: 2,
          label: "电动车",
        },
        {
          value: 4,
          label: "电动汽车",
        },
      ],
      car: "",
      users: "",
      FormData: {
        // operator_id : sessionStorage.getItem('userID')
        username: localStorage.getItem("username"),
      },
      tableData: [],
      tableData1: {},
      isadmin: false,

      //   分页
      currentPage: 1,
      pageSize: 20,
      total: 0,
      checked: 0,
      small: true,
    });
    const rounding = (row, column) => {
      return parseFloat(
        row[column.property] ? row[column.property] : "0"
      ).toFixed(2);
    };
    const formatDate = (row, column) => {
      // 获取单元格数据
      let data = row[column.property];
      if (data == null) {
        return null;
      }
      let now = new Date(data);
      let year =
        now.getFullYear() < 10 ? "0" + now.getFullYear() : now.getFullYear(); //得到年份
      let month =
        now.getMonth() + 1 < 10
          ? "0" + (now.getMonth() + 1)
          : now.getMonth() + 1; //得到月份
      let date = now.getDate() < 10 ? "0" + now.getDate() : now.getDate(); //得到日期
      let hour = now.getHours() < 10 ? "0" + now.getHours() : now.getHours(); //得到小时数
      let minute =
        now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes(); //得到分钟数
        let seconds = now.getSeconds() < 10 ?  "0" + now.getSeconds() : now.getSeconds(); //得到秒数

      return year + '-' + month + '-' + date +' '+hour+':'+minute+':'+seconds;
    };
    const searchBtn = () => {
      getList();
    };

    const getList = () => {
      const dataa = data.FormData;
      data.loading = true;
      independent_account_system(dataa).then((res) => {
        console.log(res, "分账明细");
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            data.tableData = res.data;
            data.total = res.data.length;
            data.tableData1 = res;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };
    const getUsername = () => {
      const username1 = localStorage.getItem("username");
      const users1 = [];
      operator_data().then((res) => {
        console.log(res, "sssssss");
        data.users = res;
        res.forEach((a) => {
          users1.push(a.username);
        });
        if (users1.indexOf(username1) == -1) {
          data.isadmin = true;
        } else {
          data.isadmin = false;
          data.FormData.username = username1;
        }
      });
    };
    const handleSizeChange = (val) => {
      data.pageSize = val;
    };
    const handleCurrentChange = (val) => {
      data.currentPage = val;
      //
    };
    onMounted(() => {
      console.log(data);
      getList();
      getUsername();
    });
    return {
      ...toRefs(data),
      rounding,
      formatDate,
      searchBtn,
      getList,
      getUsername,
      handleSizeChange,
      handleCurrentChange,
    };
  },
};
</script>

<style>
.pagination {
  display: flex;
  justify-content: space-between;
  padding: 0 67px;
  margin-top: -10px;
}

.bottomDiv {
  padding: 0.5rem;
  background-color: #fff;
}
.statistics {
  display: flex;
  width: 90%;
  padding-top: 15px;
  /* background: red; */
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
}
.statistics1 {
  display: flex;
  width: 90%;
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
}
.statistics1 > div > a {
  text-decoration: none;
  color: black;
}
.s_title {
  font-size: 14px;
}
.s_title .el-icon {
  float: right;
  color: red;
}
.p_bottom .el-icon {
  float: right;
  color: green;
}
.s_water {
  line-height: 40px;
  font-size: 19px;
  /* font-weight: bold; */
}
.s_profress {
  line-height: 25px;
}
.statistics > div {
  width: 16%;
  margin: 10px auto;
  background: #f0f2f5;
  /* border: 1px solid red; */
  padding: 10px 20px;
  border-radius: 5px;
  /* height: 200px; */
}
.statistics > div > .el-progress {
  width: 45%;
}
.el-progress /deep/ .el-progress-bar__outer {
  --el-border-color-lighter: #fff;
}
.statistics1 > div {
  width: 13%;
  margin: 10px auto;
  /* border: 1px solid red; */
  padding: 10px;
  /* height: 200px; */
}

.BtnSearch {
  float: left;
  width: 60px;
  margin: 0 4px;
}
.flex1 {
  display: flex;
  /* justify-content: space-between; */
  padding: 5px 10px;
  /* margin-top: -10px; */
}
.flex1 p {
  font-size: 14px;
  margin-right: 15px;
}
.flex1 span {
  color: red;
}
.index_title {
  font-size: 18px;
  color: #333;
  border-left: 5px solid #04a0c3;
  padding-left: 25px;
  margin-bottom: 15px;
}
</style>







